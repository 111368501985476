import { Spinner } from "@material-tailwind/react";
import React from "react";

const Loader = ({
  className,
  text = "",
  fontClass,
  color = "#fff",
  textColor = "",
}) => {
  return (
    <React.Fragment>
      <div className={className}>
        <div className="flex items-center gap-3">
          <Spinner
            className="text-primary-bold"
            color="gray"
            width={16}
            height={16}
          />
          <span
            className={`${fontClass}  tracking-wider`}
            style={textColor ? { color: textColor } : null}
          >
            {text || "Loading..."}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Loader;
